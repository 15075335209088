<template>
  <div>
    <table
      class="ui striped unstackable table"
      v-if="events && events.length > 0"
    >
      <tbody>
        <tr v-for="(event, index) in events" :key="index">
          <td class="ten wide">
            <span :class="{ 'text--deleted': event.cancelled }">{{
              event.timestamp
            }}</span>
            <span
              v-if="event.cancelled && event.reason"
              class="ui text-meta orange"
            >
              <br />{{ event.reason }}
            </span>
          </td>
          <td class="three wide center aligned">
            <span v-if="event.cancelled" class="ui orange tiny label"
              >ABGESAGT</span
            >
            <span v-else class="ui olive tiny label">FINDET STATT</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="ui message info" style="margin-top: 5px">
      <div class="header">Keine Kurstermine vorhanden</div>
      <p>Für diesen Kurs sind aktuell keine kommenden Termine vorhanden.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseEventList",
  props: ["events"],
};
</script>

<style>
.text--deleted {
  text-decoration: line-through;
}
</style>
