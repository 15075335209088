<template>
  <div class="collection-item-3 w-dyn-item w-col w-col-4" v-if="event">
    <router-link
      v-if="event.id"
      :to="{ name: 'event', params: { id: event.id } }"
      class="link-block-2 w-inline-block"
      :style="{
        'background-image': `url(${backgroundImgUrl})`,
      }"
    >
      <div class="div-block-20">
        <div class="div-block-21">
          <h4 class="heading-3 heading--card heading--card-event">
            {{ event.title }}
          </h4>

          <div
            class="text-block-date-info text-meta"
            v-if="event.startAt && !event.isRecurring"
          >
            <i class="calendar alternate outline icon"></i>
            {{ event.startAt | moment("dddd, DD. MMMM YYYY") }}
          </div>
          <div
            class="text-block-date-info text-meta"
            v-else-if="event.startAt && event.isRecurring && event.nextEvent"
          >
            <i class="calendar alternate outline icon"></i>
            {{ event.nextEvent.startAt | moment("dddd, DD. MMMM YYYY") }}
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backgroundImgUrl() {
      if (this.event && this.event.imageUrl) {
        return this.event.imageUrl;
      }
      return require("@/assets/images/thumbnail.jpg");
    },
  },
};
</script>
