<template>
  <div class="section-related-content" v-if="events && events.length > 0">
    <div class="block-headline block-headline-news headline-related-content">
      Weitere Veranstaltungen
    </div>
    <div class="w-dyn-list">
      <div class="w-dyn-items w-row">
        <div v-for="event in events.slice(0, 3)" :key="event.id">
          <EventCard :event="event" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventCard from "@/components/events/EventCard";

export default {
  name: "EventsRelated",
  props: {
    event: {
      type: Object,
    },
  },
  components: {
    EventCard,
  },
  computed: {
    ...mapGetters({
      relatedEventsById: "event/relatedEventsById",
    }),
    events() {
      if (!this.event) return;
      return this.relatedEventsById(this.event.id);
    },
  },
};
</script>
