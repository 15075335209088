<template>
  <div>
    <div class="div-block-7 article-content-subpage">
      <div class="div-block-14">
        <h1>Termine</h1>
      </div>
      <div class="block-article--intro">
        <div class="block-article-content">
          <div class="w-richtext">
            <p>
              Hier findest du die nächsten Termine und Kursveranstaltungen in
              einer kompakten Übersicht. Wähle einen Termin aus, um mehr
              Informationen zu erhalten.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="ui centered stackable grid">
      <div class="seven wide column">
        <div class="block-headline">Termine</div>
        <EventList />
      </div>
      <div class="seven wide column">
        <div class="block-headline">Laufende Angebote</div>
        <CourseList />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import EventList from "@/views/EventList";
import CourseList from "@/views/CourseList";

export default {
  name: "events",
  metaInfo: {
    title: "Termine",
  },
  components: {
    EventList,
    CourseList,
  },
  computed: {
    ...mapGetters({
      events: "event/nonRecurring",
    }),
  },
};
</script>
