<template>
  <div>
    <div :class="{ 'event--single': event && !event.isRecurring }">
      <div v-if="event">
        <!-- [START] Title and Date -->
        <div class="div-block-24">
          <div class="ui breadcrumb">
            <i
              class="left angle icon divider"
              style="margin-left: -4px; margin-top: 1px"
            ></i>
            <router-link :to="{ name: 'events' }" class="section text-meta"
              >Alle Termine</router-link
            >
          </div>
          <h1
            class="heading-link heading-subpage heading-subdate"
            v-if="event.title"
          >
            {{ event.title }}
          </h1>
          <div class="div-block-22 w-clearfix">
            <div
              class="text-block-11 text-meta"
              v-if="createdAt && !isNextEventCancelled"
            >
              {{ createdAt }}
            </div>
          </div>
        </div>
        <!-- [END] Title and Date  -->
        <div class="column w-row">
          <div class="column w-col" :class="{ 'w-col-7': event.isRecurring }">
            <!-- [START] Content with Image -->
            <div class="div-block-7">
              <div class="block-article" style="margin-top: 0">
                <img
                  v-if="event.imageUrl"
                  :src="event.imageUrl"
                  :alt="event.title ? event.title : 'Event'"
                  class="image-article"
                />
                <div
                  class="block-article-content w-richtext"
                  v-html="event.content"
                ></div>
              </div>
            </div>
            <!-- [END] Content with Image -->
            <!-- [START] Course Dates -->
            <div
              class="div-block-7"
              style="padding-top: 15px"
              v-if="event.isRecurring"
            >
              <div class="block-headline block-headline-news">
                Kommende Kurstermine
              </div>
              <CourseEventList :events="event.nextEvents" :course="event" />
            </div>
            <!-- [END] Course Dates -->
          </div>
          <div class="column-2 w-col w-col-5" v-if="event.isRecurring">
            <!-- [START] Info Box -->
            <div class="div-block-25">
              <div
                class="block-date box-participation"
                v-if="event.isRecurring"
                style="margin-top: 0"
              >
                <!-- [START] User is participating -->
                <div v-if="user && subscription">
                  <div class="text-date-content">
                    <h3><i class="check icon"></i> Neuigkeiten abonniert</h3>
                    Du erhältest wichtige Neuigkeiten zu diesem laufenden Kurs
                    (z.B. Absagen, geänderte Zeiten, etc.) per E-Mail.
                  </div>
                  <div class="text-date-content text-profil-info">
                    Neue E-Mail-Adresse?&nbsp;Ändere deine Kontaktdaten ganz
                    einfach in deinem
                    <router-link :to="{ name: 'profile' }"
                      ><strong class="bold-text-2">Profil</strong></router-link
                    >. Über unsere
                    <a
                      href="https://chat.whatsapp.com/Bw2jL51iApZKa6Dse4LLJA"
                      class="bold-text-2"
                      >WhatsApp-Gruppe</a
                    >
                    erhälst du zusätzlich alle Infos zu deinem Kurs.
                  </div>
                </div>
                <!-- [END] User is participating -->
                <!-- [START] User is not participating -->
                <div v-else>
                  <div class="text-date-content">
                    <h3>
                      <i class="bullhorn icon"></i> Neuigkeiten abonnieren
                    </h3>
                    Abonniere die Neuigkeiten dieses Kurses, um wichtige
                    Änderungen (z.B. Absagen, geänderte Uhrzeiten, etc.) per
                    E-Mail zu erhalten.<br /><br />
                    Über unsere
                    <a
                      href="https://chat.whatsapp.com/Bw2jL51iApZKa6Dse4LLJA"
                      class="bold-text-2"
                      >WhatsApp-Gruppe</a
                    >
                    kannst du zusätzlich alle Infos zu deinem Kurs erhalten.
                  </div>
                </div>
                <!-- [END] User is not participating -->
              </div>

              <a
                v-if="subscription && user"
                class="w-button button red bordered large"
                @click="handleParticipation(false)"
                >Neuigkeiten nicht mehr abonnieren</a
              >
              <a
                v-else
                class="w-button button blue bordered large"
                @click="handleParticipation(true)"
                >Neuigkeiten abonnieren</a
              >
            </div>
            <!-- [END] Info Box -->
          </div>
        </div>
      </div>
      <div v-else style="max-width: 500px; margin: 0 auto">
        <!-- [START] Title and Date -->
        <div class="div-block-24">
          <div class="ui breadcrumb">
            <i
              class="left angle icon divider"
              style="margin-left: -4px; margin-top: 1px"
            ></i>
            <router-link :to="{ name: 'events' }" class="section text-meta"
              >Alle Termine</router-link
            >
          </div>
          <h1 class="heading-link heading-subpage heading-subdate">
            Termin nicht mehr verfügbar
          </h1>
        </div>
        <div class="div-block-7">
          <div style="margin-top: 1em">
            <div class="w-richtext">
              Der ausgewählte Termin ist nicht mehr verfügbar, da er bereits in
              der Vergangenheit liegt oder zwischenzeitlich gelöscht wurde.
            </div>
            <div style="margin-top: 1em">
              <router-link :to="{ name: 'events' }" class="ui primary button"
                >Alle Termine ansehen</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- [END] content -->
    <!-- [START] Releated Events -->
    <EventsRelated :event="event" />
    <!-- [END] Releated Events -->
  </div>
</template>

<script>
import CourseEventList from "@/components/events/CourseEventList.vue";
import EventsRelated from "@/components/events/EventsRelated";
import { mapGetters } from "vuex";

export default {
  name: "Event",
  components: {
    EventsRelated,
    CourseEventList,
  },
  computed: {
    ...mapGetters({
      eventById: "event/eventById",
      subscriptionByEventId: "subscription/subscriptionByEventId",
      user: "user/current",
    }),
    event() {
      return this.eventById(this.$route.params.id);
    },
    createdAt() {
      if (!this.event) {
        return;
      }

      if (
        this.event.isRecurring &&
        this.nextEvents &&
        this.nextEvents.length > 0
      ) {
        return `${this.$moment(this.nextEvents[0].startAt).format(
          "dddd, DD. MMMM YYYY"
        )} um ${this.$moment(this.event.startAt).format("HH:mm")} Uhr`;
      } else if (!this.event.isRecurring) {
        if (this.event.isAllDay && this.event.isMultiDay) {
          return `${this.$moment(this.event.startAt).format(
            "dddd, DD. MMMM YYYY"
          )} - ${this.$moment
            .unix(this.event.endAt.seconds)
            .format("dddd, DD. MMMM YYYY")}`;
        } else if (this.event.isAllDay) {
          return `${this.$moment(this.event.startAt).format(
            "dddd, DD. MMMM YYYY"
          )}`;
        }
        return `${this.$moment(this.event.startAt).format(
          "dddd, DD. MMMM YYYY"
        )} um ${this.$moment(this.event.startAt).format("HH:mm")} Uhr`;
      }

      return null;
    },
    nextEvents() {
      if (
        !this.event ||
        (this.event && !this.event.startAt) ||
        (this.event && !this.event.endAt) ||
        (this.event && !this.event.recurrenceWeekdays)
      ) {
        return;
      }
      var startDate = this.event.startAt.seconds
        ? this.$moment.unix(this.event.startAt.seconds)
        : this.$moment(this.event.startAt);
      var endDate = this.$moment.unix(this.event.endAt.seconds);
      var newEndDate = this.$moment(endDate).add(1, "days");
      const recurrenceWeekdays = $.trim(this.event.recurrenceWeekdays);

      var condition = this.$moment()
        .recur({
          start: startDate,
          end: newEndDate,
        })
        .every(recurrenceWeekdays.split(",").map(Number))
        .daysOfWeek();

      var nextDates = condition.all();
      let nextEvents = [];
      nextDates.forEach(nextDate => {
        // Override hours for next date
        nextDate = nextDate.set({ hour: 0 });

        // Skip dates later than today
        const diff = nextDate.diff(this.$moment(), "days");
        if (diff < 0) {
          return;
        }

        // Check if next date is listed in the exception list and won't be displayed
        let exception;
        if (this.event.exceptions && this.event.exceptions.length > 0) {
          exception = this.event.exceptions.find(
            exception =>
              this.$moment(exception).toISOString() ===
              this.$moment(nextDate).toISOString()
          );
        }

        // To not show deleted events in the list
        if (exception) return;

        let cancelledEvent;
        for (const key in this.event.cancelled) {
          if (key == this.$moment(nextDate).toISOString()) {
            cancelledEvent = {
              startAt: nextDate,
              timestamp: this.$moment(nextDate).format("dddd, DD. MMMM YYYY"),
              reason: this.event.cancelled[key],
              cancelled: true,
              deleted: exception ? true : false,
            };
          }
        }
        if (!cancelledEvent) {
          nextEvents.push({
            startAt: nextDate,
            timestamp: this.$moment(nextDate).format("dddd, DD. MMMM YYYY"),
            cancelled: false,
            deleted: exception ? true : false,
          });
        } else {
          nextEvents.push(cancelledEvent);
        }
      });

      return nextEvents;
    },
    subscription() {
      return this.subscriptionByEventId(this.event.id);
    },
    isNextEventCancelled() {
      return (
        this.event &&
        this.event.isRecurring &&
        this.event.nextEvents &&
        this.event.nextEvents.length > 0 &&
        this.event.nextEvents[0].cancelled
      );
    },
  },
  metaInfo() {
    if (!this.event || !this.event.title) {
      return {
        title: "Termin",
      };
    }
    return {
      title: this.event.title,
      meta: [
        { vmid: "description", name: "description", content: this.event.title },
      ],
    };
  },
  methods: {
    handleParticipation(isParticipating) {
      if (!this.user) {
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.fullPath },
        });
        return false;
      }
      if (isParticipating) {
        this.addSubscription();
      } else {
        this.deleteSubscription();
      }
    },
    addSubscription() {
      this.$store.dispatch("subscription/add", { event: this.event });
    },
    updateSubscription(channel, event) {
      let localValue;
      if (channel === "email") {
        localValue = event.target.checked ? this.user.email : null;
      } else if (channel === "sms") {
        localValue = event.target.checked ? this.user.phoneNumber : null;
      }
      this.$store.dispatch("subscription/updateChannel", {
        subscription: this.subscription,
        channel: channel,
        value: localValue,
      });
    },
    deleteSubscription() {
      this.$store.dispatch("subscription/delete", {
        subscription: this.subscription,
      });
    },
  },
};
</script>

<style scoped>
.event--single {
  max-width: 700px;
  margin: 0 auto;
}
</style>
